import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestService } from './request.service';
import { Url } from '../../shared/util/url.class';
import { UrlService } from './url.service';
import { IUser } from '../../shared/auth/user';
import { CustomURLEncoder } from 'src/app/shared/custom-url-encoder.class';

@Injectable()
export class AuthenticationService {
  private _authUser: IUser;

  private _redirectRoute: string;

  private _newUser: boolean = false;

  private $authenticationSuccess: Subject<IUser>;

  public get redirectRoute() {
    return this._redirectRoute;
  }

  public set redirectRoute(route) {
    this._redirectRoute = route;
  }

  public get authUser() {
    return this._authUser;
  }

  public set authUser(user: IUser) {
    this._authUser = user;
  }

  constructor(private requestService: RequestService, private urlService: UrlService, private router: Router) {
    this.$authenticationSuccess = new Subject();
  }

  public logout(): Observable<unknown> {
    const url: Url = this.urlService.baseApiUrl;
    this._redirectRoute = null;
    url.push('security_logout');

    return this.requestService.get<unknown>(url.serialize()).pipe(
      map(() => {}),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return [];
      })
    );
  }

  public resetPassword(username: string) {
    const url: Url = this.urlService.baseUrl;
    url.push('api');
    url.push('password');
    url.push('reset');
    url.push('save');
    const headers: any = new HttpHeaders();

    return this.requestService.post(
      url.serialize(),
      { _username: username },
      headers.append('Content-Type', 'application/x-www-form-urlencoded')
    );
  }

  public authenticate(username: string, password: string, rememberMe?: boolean): Observable<unknown> {
    const url: Url = this.urlService.baseUrl;
    url.push('signin_check');
    const headers = new HttpHeaders();

    let body: HttpParams = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('_username', username)
      .set('_password', password);

    if (rememberMe) {
      body = body.set('_remember_me', rememberMe);
    }

    return this.requestService
      .auth(url.serialize(), body.toString(), headers.append('Content-Type', 'application/x-www-form-urlencoded'))
      .pipe(
        map((user: IUser) => {
          this.authUser = user;
          this.$authenticationSuccess.next(this.authUser);
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  public checkServerAuthentication(route: string): Observable<unknown> {
    const url: Url = this.urlService.baseAppUrl;
    url.push('is-authenticated');
    this.redirectRoute = route;

    return this.requestService.get<IUser>(url.serialize()).pipe(
      map((data: IUser) => {
        this.authUser = data;
        this.$authenticationSuccess.next(this.authUser);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  public getUser(): IUser {
    return this._authUser;
  }

  public checkRole(role: string): boolean {
    return this.authUser.roles.includes(role);
  }

  public is_NewUser(): boolean {
    return this._newUser;
  }

  public onAuthenticationSuccess(): Subject<IUser> {
    return this.$authenticationSuccess;
  }

  public getAndClearRedirectRoute(): string {
    const rr = this.redirectRoute;
    this._redirectRoute = null;
    return rr;
  }
}
