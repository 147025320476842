<div class="modal-header">
    <h4 class="modal-caption">{{ caption }}</h4>
    <button style="outline: none" type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
    <div class="modal-menu">
        <div class="row">
            <div class="col-6">
                <button type="button" class="fn-btn fn-btn-success full" (click)="onConfirm()">{{ confirm }}</button>
            </div>
            <div class="col-6">
                <button type="button" class="fn-btn fn-btn-default full" (click)="onCancel()">{{ cancel }}</button>
            </div>
        </div>
    </div>
</div>
