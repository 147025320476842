import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { DefaultView } from '../../shared/class/defualt-view.class';

@Injectable()
export class ProfileStateService {
  private localeSubject: Subject<string> = new Subject<string>();
  private localeObservable: Observable<string>;
  private _locale: string;
  public defaultView: BehaviorSubject<DefaultView> = new BehaviorSubject<DefaultView>({
    default: {
      pageSize: 25
    },
    product: {
      orderBy: 'timeCreated',
      orderDirection: 'DESC',
      additionalOrderBy: '',
      additionalOrderByDirection: ''
    },
    productStockChange: {
      pageSize: 10
    },
    categoryProductGroups: {
      pageSize: 10,
      filters: []
    },
    dashboardLowStockProducts: {
      pageSize: 10,
      filters: ['low']
    },
    productSearch: {
      pageSize: 10,
      filters: ['low']
    },
    dashboardOrders: {
      pageSize: 10,
      filter: 'pending'
    },
    order: {
      filter: 'all',
      orderBy: 'timeCreated',
      orderDirection: 'DESC'
    },
    stocktakingAll: {
      filter: 'all',
      orderBy: 'timeCreated',
      orderDirection: 'DESC'
    },
    stocktakingChange: {
      pageSize: 10,
      filter: 'all',
      orderBy: 'concreteProduct.name',
      orderDirection: 'ASC'
    },
    productGroupProducts: {
      pageSize: 10,
      orderBy: 'timeCreated',
      orderDirection: 'DESC'
    },
    sales: {
      orderBy: 'timeSold',
      orderDirection: 'DESC'
    },
    productGroupsAll: {
      pageSize: 10
    },
    categoryProducts: {
      pageSize: 10,
      filters: []
    },
    stockTransfer: {
      filter: 'all',
      pageSize: 10,
      orderBy: 'timeCreated',
      orderDirection: 'DESC'
    },
    pickingSlip: {
      pageSize: 10,
      orderBy: 'timeCreated',
      orderDirection: 'DESC'
    }
  });
  private defaultViewState: DefaultView;

  constructor() {
    this.localeObservable = this.localeSubject.asObservable();
  }

  changeLocale(locale: string) {
    this._locale = locale;
    this.localeSubject.next(locale);
  }

  subscribeLocale(clb: (e: string) => {}) {
    this.localeSubject.subscribe(clb);
  }

  observeLocale() {
    return this.localeObservable;
  }

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
  }

  public setDefaultViewAll(defaultView: DefaultView) {
    this.defaultViewState = defaultView;
    this.defaultView.next(this.defaultViewState);
  }
}
