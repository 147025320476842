import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-boson-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
  caption: string;

  message = '';

  confirmLabel = 'lbl.Yes';

  cancelLabel = 'lbl.No';

  confirm = 'Yes';

  cancel = 'No';

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (!this.caption) {
      this.caption = 'txt.Confirm_action';
    }
    this.onClose = new Subject();

    this.translateService.get(this.caption).subscribe((res: string) => {
      this.caption = res;
    });

    this.translateService.get(this.confirmLabel).subscribe((res: string) => {
      this.confirm = res;
    });

    this.translateService.get(this.cancelLabel).subscribe((res: string) => {
      this.cancel = res;
    });

    this.translateService.get(this.message).subscribe((res: string) => {
      this.message = res;
    });
  }

  public onConfirm(): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(() => {
      this.onClose.next(true);
    });
  }

  public onCancel(): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(() => {
      this.onClose.next(false);
    });
  }
}
