import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * This service will be used to hold simple Subjects/Observables that will be used only for passing some kind of info
 * between components, so components know what is happening and they can react appropreatly
 */
export class CommunicationService {
  /**
   * Subject which will emit current customer user
   */
  tabletView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Subject which will create dialog/toastr for not authenticated users
   */
  notAuthenticated$: Subject<boolean> = new Subject();

  /**
   * Subject which will create dialog/toastr for forbidden actions
   */
  forbidden$: Subject<boolean> = new Subject();

  /**
   * Subject which will create dialog/toastr for requests that send unappropirate data
   */
  wrongData$: Subject<boolean> = new Subject();

  /**
   * Subject which will create dialog/toastr for requests that send unappropirate data
   */
  internalServerError$: Subject<boolean> = new Subject();

  resetTreeActiveClassShopProductList$: Subject<number> = new Subject();

  /**
   * Subject which will boolean if backlayer is opened/closed
   */
  openBacklayer$: Subject<boolean> = new Subject();

  /**
   * Sidebar hover indicator
   */
  sidebarHover$: Subject<boolean> = new Subject();

  /**
   * Show spinner
   */
  showSpinner$ = new Subject<boolean>();

  /**
   * collapsed sidebar
   */
  private collapseSidebarSubject: Subject<boolean> = new Subject();

  constructor() {}

  public getCollapseSidebarSubject() {
    return this.collapseSidebarSubject;
  }
}
