<div class="modal-header">
    <h4 class="modal-caption">{{ caption }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">{{ message }}</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onOption1()">{{ option1 }}</button>
    <button type="button" class="btn btn-default" (click)="onOption2()">{{ option2 }}</button>
    <button type="button" class="btn btn-default" (click)="onCancel()">{{ cancel }}</button>
</div>
