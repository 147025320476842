import { Injectable, Injector } from '@angular/core';
import { IResponse } from '../interfaces/response.interface';
import { Location } from '../interfaces/location.interface';
import { Subject } from 'rxjs';
import { AbstractService } from 'src/app/shared/util/abstract.service';
import { Url } from 'src/app/shared/util/url.class';
import { CallbackFunction } from 'src/app/shared/interface/callback-function.interface';
import { IErrorServerResponse } from 'src/app/shared/interface/error.server.response.interface';
import { IPayload } from 'src/app/shared/interface/payload.interface';

@Injectable()
export class LocationService extends AbstractService {
  public locationsChanged: Subject<boolean> = new Subject();
  public locationHovered: Subject<Location> = new Subject();

  constructor(injector: Injector) {
    super(injector);
  }

  private getUrl() {
    const url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventLocation');

    return url;
  }

  public getAllLocations(
    data: { pageSize?: number; orderDirection?: string; currentPage?: number },
    clb: CallbackFunction<IPayload<any[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    data.pageSize ? url.put('pageSize', data.pageSize) : url.put('pageSize', 20);
    url.put('orderBy', 'addressName');
    data.orderDirection ? url.put('orderDirection', data.orderDirection) : url.put('orderDirection', 'ASC');
    data.currentPage ? url.put('page', data.currentPage) : url.put('page', 1);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getLocation(
    locationId: number,
    clb: CallbackFunction<IResponse<Location>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push(locationId);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public createLocation(
    location: any,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    this._subscribe(this.requestService.post(url.serialize(), location), clb, error, complete);
  }

  public updateLocation(
    locationId: number,
    location: any,
    clb: CallbackFunction<IResponse<Location>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push(locationId);
    this._subscribe(this.requestService.put(url.serialize(), location), clb, error, complete);
  }

  public deleteLocation(
    locationId: number,
    clb: CallbackFunction<IResponse<Location>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push(locationId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public search(
    searchTerm: any,
    pageSize: number,
    orderDirection: string,
    page: number,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push('search');

    !pageSize ? url.put('pageSize', 10) : url.put('pageSize', pageSize);

    !orderDirection ? url.put('orderDirection', 'ASC') : url.put('orderDirection', orderDirection);

    !page ? url.put('page', 1) : url.put('page', page);

    url.put('orderBy', 'addressName');
    this._subscribe(this.requestService.post(url.serialize(), searchTerm), clb, error, complete);
  }

  public uploadPhoto(
    locationId: number,
    image: any,
    thumbnail: any,
    clb: CallbackFunction<IResponse<Location>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push(locationId);
    url.push('image');
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', image.name);
    formData.append('thumbnail', thumbnail);
    this._subscribe(this.requestService.post(url.serialize(), formData), clb, error, complete);
  }

  public removePhoto(
    locationId: number,
    clb: CallbackFunction<IResponse<Location>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url = this.getUrl();
    url.push(locationId);
    url.push('image');
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public getEventLocationTypes(
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventLocationType');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }
}
