import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipe/pipe.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SEARCH_SERVICE_TOKEN } from '../services/search.service';
import { ProductService } from 'src/app/panel/erp/services/entity-service/product.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PipeModule, TranslateModule, NgbModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  providers: [
    {
      provide: SEARCH_SERVICE_TOKEN,
      useClass: ProductService
    }
  ]
})
export class NavbarModule {}
