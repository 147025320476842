import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { AuthenticationService } from '../core/services/authentication.service';
import { IUser } from '../shared/auth/user';

@Injectable({
  providedIn: 'root'
})
export class RoutekeeperGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { url } = state;

    return this.checkLogin(url);
  }

  private checkRoutes(url: string): boolean {
    const user = this.authenticationService.getUser() as IUser;
    /** TODO CHANGE TO supplier FROM localhost */
    if (location.host.includes('supplier')) {
      if (url.startsWith('/panel')) {
        this.router.navigate(['/supplier']);
      }
      if (user && user.roles.includes('ROLE_SUPPLIER')) {
        return true;
      } else {
        if (url.startsWith('/supplier/login') || url.startsWith('/supplier/register')) {
          return true;
        }
        return null;
      }
    }
    if (url.startsWith('/login')) {
      return true;
    }
    if (url.startsWith('/reset-password')) {
      return true;
    }
    if (user) {
      if (url.startsWith('/management')) {
      }
      if (url.startsWith('/panel')) {
        return true;
      }
      if (url.startsWith('/employee')) {
      }
      this.router.navigate(['/forbidden']);
      return false;
    }
    return null;
  }

  checkLogin(url: string): Observable<boolean> {
    const r = this.checkRoutes(url);
    if (r !== null) {
      return of(r);
    }

    return (
      this.authenticationService
        .checkServerAuthentication(url)
        .pipe(
          map(() => {
            const rr = this.checkRoutes(url);
            if (rr !== null) {
              return rr;
            }
            return false;
          }),
          catchError((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
            return of(false);
          })
        )
        // We have to put some daley so Enums could be translated on time (before use in ng-select,
        // ng-select [items] are immutable). Best solution would be to block this until onAuthenticationSuccess procedure has finished, but it's
        // tricky to implement that
        .pipe(delay(750))
    );
  }
}
