import { Injectable, Injector } from '@angular/core';
import { AbstractEntityService } from '../abstract.entity.service';
import { IConcreteProduct } from '../../entity/concreteproduct';
import * as moment from 'moment';
import { PaginateParameters, SearchService } from 'src/app/shared/services/search.service';
import { CallbackFunction } from 'src/app/shared/interface/callback-function.interface';
import { Url } from 'src/app/shared/util/url.class';
/**
 * Created by angus on 20.03.16unt: 2.
 */
@Injectable()
export class ProductService extends AbstractEntityService<IConcreteProduct> implements SearchService {
  constructor(injector: Injector) {
    const name = 'product';
    super(injector, name, [] /*paginationService*/);
  }

  public updateGroups<T>(
    id: number,
    data: any,
    clb: CallbackFunction<T>,
    error?: CallbackFunction<any>,
    complete?: () => void
  ) {
    const url = this.getPreUrl().push(id).push('groups');
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  getForIngredient(ingredientId: number, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.path.push('ingredient');
    url.path.push(ingredientId);
    url.arrayParams['_relations'] = this._relations;
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  updateStockAmount(id: number, data: any, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push(id);
    url.push('update-stock-amount');
    url.arrayParams['_relations'] = this._relations;
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  paginateRelatedStockChanges<T>(
    productId: number,
    page: number,
    pageSize: number,
    clb: CallbackFunction<T>,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.getPreUrl();
    url.path.push(productId);
    url.path.push('stock-change');
    url.path.push('paginate');
    url.put('page', page);
    url.put('page_size', pageSize);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getSearchResultRoute(): string {
    return '/panel/erp/product/search';
  }

  downloadProducts(categoryIds: any[], storageIds: any[]) {
    const url: Url = this.getPreUrl();
    url.path.push('download');
    url.putArray('categoryIds', categoryIds);
    url.putArray('storageIds', storageIds);
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
      withCredentials: true
    };

    return this.requestService.get<any>(url.serialize(), options);
  }

  getProductsPerCategories<T>(
    search: string,
    categoryIds: any[],
    page: number,
    pageSize: number,
    clb: CallbackFunction<T>,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.getPreUrl();
    url.path.push('categories');
    if (search) {
      url.put('search', search);
    }
    url.put('page', page);
    url.put('page_size', pageSize);
    url.putArray('categoryIds', categoryIds);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getProductsPerCategoriesWithAdditionalSorting<T>(
    search: string,
    categoryIds: any[],
    page: number,
    pageSize: number,
    orderBy: string,
    orderByDirection: string,
    clb: CallbackFunction<T>,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.getPreUrl();
    url.path.push('categories');
    url.path.push('additional-sorting');
    if (search) {
      url.put('search', search);
    }
    url.put('page', page);
    url.put('page_size', pageSize);
    url.putArray('categoryIds', categoryIds);
    url.put('additional_order_by', orderBy);
    url.put('additional_order_by_direction', orderByDirection);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getProductsPerStorages<T>(
    storageIds: any[],
    search: string,
    page: number,
    pageSize: number,
    orderBy: string,
    orderByDirection: string,
    clb: CallbackFunction<T>,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.getPreUrl();
    url.path.push('storages');
    url.put('search', search);
    url.put('page', page);
    url.put('page_size', pageSize);
    url.putArray('storageIds', storageIds);
    url.put('additional_order_by', orderBy);
    url.put('additional_order_by_direction', orderByDirection);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  paginateProducts<T>(paginateParameters: PaginateParameters<T>, search: string, shopId?: number) {
    const url: Url = this.getPreUrl();
    url.put('page', paginateParameters.page);
    url.put('page_size', paginateParameters.limit);
    url.put('order_by', paginateParameters.orderBy);
    url.put('additional_order_by', paginateParameters.additionalOrderBy);
    url.put('additional_order_by_direction', paginateParameters.additionalOrderByDirection);
    url.put('order_direction', paginateParameters.orderDirection);
    url.put('search', search);
    url.put('shopId', shopId);
    if (paginateParameters.timespan) {
      url.put('from', Date.parse(moment.utc(paginateParameters.timespan.from).toDate().toUTCString()) / 1000);
      url.put('to', Date.parse(moment.utc(paginateParameters.timespan.to).toDate().toUTCString()) / 1000);
    }
    return this._subscribe(
      this.requestService.get(url.serialize()),
      paginateParameters.clb,
      paginateParameters.error,
      paginateParameters.complete
    );
  }

  paginateProductItems<T>(
    paginateParameters: PaginateParameters<T>,
    search: string,
    costCenterIds: any,
    shopId?: number,
    productGroupId?: number,
    costCenterId?: number
  ) {
    const url: Url = this.url();
    url.push('getProducts');
    url.put('page', paginateParameters.page);
    url.put('page_size', paginateParameters.limit);
    url.put('order_by', paginateParameters.orderBy);
    url.put('order_direction', paginateParameters.orderDirection);
    url.put('productGroupId', productGroupId);
    url.put('costCenterId', costCenterId);
    url.put('search', search);
    url.put('shopId', shopId);
    if (paginateParameters.timespan) {
      url.put('from', Date.parse(moment.utc(paginateParameters.timespan.from).toDate().toUTCString()) / 1000);
      url.put('to', Date.parse(moment.utc(paginateParameters.timespan.to).toDate().toUTCString()) / 1000);
    }
    return this._subscribe(
      this.requestService.post(url.serialize(), costCenterIds),
      paginateParameters.clb,
      paginateParameters.error,
      paginateParameters.complete
    );
  }

  mergeProducts(data: any, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push('merge');
    return this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  getDistinctPackagingsWithWeight(
    shopProductId: number,
    clb: (e: any) => any,
    error?: (e: any) => any,
    complete?: () => any
  ) {
    const url: Url = this.getPreUrl();
    url.push(shopProductId);
    url.push('packagings');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getPackagingsWithPrice(productId: number, clb: (e: any) => any) {
    const url: Url = this.getPreUrl();
    url.push(productId);
    url.push('packagings-with-price');
    return this._subscribe(this.requestService.get(url.serialize()), clb);
  }

  getProductPackagings(erpId: number, productId: number, clb: (e: any) => any) {
    const url: Url = this.urlService.baseApiUrl;
    url.push(erpId);
    url.push('product');
    url.push(productId);
    url.push('packagings');
    return this._subscribe(this.requestService.get(url.serialize()), clb);
  }

  searchForShopProduct(shopId: number, costCenter: number, keyword: string) {
    const url: Url = this.getBaseUrl();
    url.push('shops');
    url.push(shopId);
    url.push('costcenters');
    url.push(costCenter);
    url.push('search');
    url.put('q', keyword);
    return this.requestService.get(url.serialize());
  }
  // api/erpSettings/productGroups/{id grupe}/{id produkta}/{novi pozlozaj produkta (1, 2, .....)}
  getConcreteProducts(
    data: { groupId: number; productId: number; position: number },
    clb?: (e: any) => any,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('erpSettings');
    url.push('productGroups');
    url.push(data.groupId);
    url.push(data.productId);
    url.push(data.position);
    return this._subscribe(this.requestService.put(url.serialize(), ''), clb, error, complete);
  }

  updateStockLimit(id: number, data: any, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push(id);
    url.push('update-stock-limit');
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  setPreferredShopUnit(id: number, data: any, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push(id);
    url.push('set-preferred-shop-unit');
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  checkIfPPAExistsForChoosenRecipe(id: number, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push('recipe-ppa');
    url.push(id);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getDesiredPriceForPPA(id: number, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push('recipe-ppa-price');
    url.push(id);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  productCategories(id: number, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.getPreUrl();
    url.push(id);
    url.push('category');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getProductsPerNameWithoutExcludedProducts<T>(
    search: string,
    productsToExclude: any[],
    page: number,
    pageSize: number,
    clb: CallbackFunction<T>,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    const url: Url = this.getPreUrl();
    url.path.push('search-products-without-specified-products');
    if (search) {
      url.put('search', search);
    }
    url.put('page', page);
    url.put('page_size', pageSize);
    url.putArray('productsToExclude', productsToExclude);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }
}
