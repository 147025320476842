import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NavbarService } from 'src/app/shared/navbar/navbar.service';
import { IUser } from '../auth/user';
import { AppLocaleStorageService } from '../util/local-storage.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  message: string;
  $subs: Subscription;
  theme: string;

  private user: IUser;

  constructor(
    private navbarService: NavbarService,
    private authenticationService: AuthenticationService,
    public alss: AppLocaleStorageService
  ) {
    this.theme = this.alss.getItem('viewMode');
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
