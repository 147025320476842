import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DummyLocaleStorage } from './dummy-locale-storage';

@Injectable()
export class AppLocaleStorageService {
  mLocaleSorage: DummyLocaleStorage;

  constructor(@Inject(PLATFORM_ID) private platformId: unknown) {
    if (isPlatformBrowser(this.platformId)) {
      this.mLocaleSorage = localStorage;
    } else {
      this.mLocaleSorage = new DummyLocaleStorage();
    }
  }

  getStorage(): Storage {
    return this.mLocaleSorage;
  }

  getItem(key: string): string {
    return this.mLocaleSorage.getItem(`foodnotify.${key}`);
  }

  setItem(key: string, item: string): void {
    this.mLocaleSorage.setItem(`foodnotify.${key}`, item);
  }

  removeItem(key: string): void {
    this.mLocaleSorage.removeItem(`foodnotify.${key}`);
  }

  getStoragePath(): string {
    return 'foodnotify';
  }

  clear(): void {
    this.mLocaleSorage.clear();
  }
}
