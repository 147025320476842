import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RecipeDetailsViewState } from '../components/recipe-details/recipe-details-view-state.class';
import { EventCatering } from '../interfaces/event-catering.interface';
import { EventUser } from '../interfaces/user-logged.interface';

/**
 * This service will be used to hold simple Subjects/Observables that will be used only for passing information
 * between components, so components know what is happening and they can react appropreatly
 */

@Injectable({
  providedIn: 'root'
})
export class EventCateringCommunicationService {
  constructor() {}

  /** Edit mode toggle subject for event details.  */
  editMode$: Subject<boolean> = new Subject();

  /**  * Edit mode toggle variable for event details. */
  editMode: boolean;

  /** ID of currently selected event. */
  currentEventId: string;

  /**  Currently slected event.*/
  currentEvent: EventCatering;

  /** Currently slected subject status of event. */
  eventStatus$: Subject<string> = new Subject();

  /**  Currently slected status of event. */
  eventStatus: string;

  /** Currently loged user */

  loggedUserSubject: Subject<EventUser> = new Subject();
  loggedUser: EventUser;
  masterUser: boolean;

  /** Number of staff members on one event */
  numberOfStaff: number = 0;

  /**  Emits the response of confirm modal */
  emitConfirmModal: Subject<any> = new Subject();

  /**  Emits the response of confirm modal  */
  eventReceived: Subject<boolean> = new Subject();

  /** Emits the order of settings items */
  orderByASCSettings: Subject<boolean> = new Subject();

  /** Shows/hides spinner */
  toggleSpinner: Subject<boolean> = new Subject();

  /**  Controls the display of the event detail global spinner  */
  eventDetailSpinnerActive: boolean = false;

  /** Emits the event types from custom dropdown */
  public eventTypesSubject: Subject<any[]> = new Subject();

  /** Emits the service types from custom dropdown  */
  public serviceTypesSubject: Subject<any[]> = new Subject();

  /**  Emits the customers from custom dropdown */
  public customersSubject: Subject<any[]> = new Subject();

  /** Emits the locations from custom dropdown  */
  public locationsSubject: Subject<any[]> = new Subject();

  /**  Emits the consumerGroups from custom dropdown */
  public consumerGroupsSubject: Subject<any[]> = new Subject();

  /** Emits the new location from custom dropdown */
  public addLocation: Subject<any> = new Subject();

  /** Emits the new customer from custom dropdown */
  public addCustomer: Subject<any> = new Subject();

  /** Emits the new event type from custom dropdown  */
  public addEventType: Subject<any> = new Subject();

  /** Emits the new service type from custom dropdown */
  public addServiceType: Subject<any> = new Subject();

  /** Emits the new consumer group type from custom dropdown */
  public addConsumerGroupType: Subject<any> = new Subject();

  /** Emits the new order data category type from custom dropdown  */
  public orderCategoryTypeChanged: Subject<string> = new Subject();

  public currentLang: Subject<string> = new Subject();

  /**  resetEmit on calendar  */
  public resetEmit: Subject<boolean> = new Subject();

  /**  Emits an event that closes the pop up in recipe tree view  */
  public closePopUpTreeView: Subject<boolean> = new Subject();

  /**  Emits overlayActive in recipe tree view  */
  public overlayActiveTreeView: Subject<boolean> = new Subject();

  public closedNavbarHeader: boolean;

  /** Emits an event that signals that all subrecipes should be opened in recipe tree view. */
  public openAllSubrecipes: Subject<boolean> = new Subject();

  /**  Emits an event that signals that finance display cateogry has changed. The new value is emitted also. */
  public financeCategoryChanged: Subject<string> = new Subject();

  /** Event and Type saved for representation on each event entered */
  public event: string;
  public type: string;

  /** Emits when buttons for multiple add clicked */
  public templateAddClicked: Subject<boolean> = new Subject();
  public groupAddClicked: Subject<boolean> = new Subject();
  public courseAddClicked: Subject<boolean> = new Subject();
  public recipeAddClicked: Subject<boolean> = new Subject();

  /** Emits when Copy template clicked */
  public copyTemplateClicked: Subject<boolean> = new Subject();

  /** Emits when meals, drinks or equipment loaded */
  public drinksStructureExistsSubject: Subject<boolean> = new Subject();
  public mealsStructureExistsSubject: Subject<boolean> = new Subject();
  public equipmentStructureExistsSubject: Subject<boolean> = new Subject();

  /** Save when meals, drinks or equipment loaded */
  public mealsStructureExists: boolean;
  public drinksStructureExists: boolean;
  public equipmentStructureExists: boolean;

  /** Save Copy templates from option */
  public copyTemplatesFrom = 'eventDrinkGroup';
  /**Save Translation of statuses */
  public statusInPlanning = 'In Planung';
  public statusAssigned = 'Beauftragt';
  public statusInProgress = 'In Bearbeitung';
  public statusReady = 'Bereit';
  public statusCanceled = 'Abgesagt';

  /**keeps orders */
  public ordersChanged: boolean;
  /**backdrop notification */
  public backdropClick = new Subject();
  /**open register modal */
  public registerModalOpen: boolean;
  /**open register modal */
  public blacklistModalOpen: boolean;
  /**  Bound to the toggle switch in staff-printout view. Signals if number of guests needs to be used in portions calculation.  */
  public useNumberOfGuests: boolean = false;
  /**  Bound to the toggle switch in recipe-detail view. Signals if number of guests needs to be used in portions calculation. */
  public useNumberOfGuestsRecipeDetails: boolean = false;

  public newLocation: Subject<any> = new Subject();
  public newEvent: Subject<any> = new Subject();
  public newType: Subject<any> = new Subject();
  public newCustomer: Subject<any> = new Subject();
  public onStaffChange: Subject<any> = new Subject();
  public eventStaff = new BehaviorSubject(null);

  /**
   * Unit conversion for ingredients
   * @param quantity
   * @param from_unit
   */
  public convertQuantity(quantity: number, from_unit: string): any {
    let to_unit;
    if (quantity >= 1000) {
      if (
        from_unit === 'g' ||
        from_unit === 'gr' ||
        from_unit === 'grm' ||
        from_unit === 'gram' ||
        from_unit === 'gramm'
      ) {
        quantity /= 1000;
        to_unit = 'kg';
      } else if (from_unit === 'ml' || from_unit === 'mlt') {
        quantity /= 1000;
        to_unit = 'l';
      } else {
        to_unit = from_unit;
      }
    } else {
      to_unit = from_unit;
    }

    return [quantity, to_unit];
  }

  /**
   *
   * @param quantity
   * @param from_unit
   * @param convert
   * @returns map array
   */
  public convertQuantityOrder(quantity: number, from_unit: string) {
    if (from_unit !== null && from_unit !== undefined) {
      if (
        from_unit.toLowerCase() === 'kg' ||
        from_unit.toLowerCase() === 'kgm' ||
        from_unit.toLowerCase() === 'l' ||
        from_unit.toLowerCase() === 'lt'
      ) {
        return [quantity / 1000, from_unit];
      }
    }
    return [quantity, from_unit];
  }

  /**
   * Convert to gramms
   * @param quantity
   * @param unit
   */
  public convertToGramms(quantity: number, unit: string): any {
    if (unit === null || unit === undefined) return unit;
    if (unit.toLowerCase() === 'kg' || unit.toLowerCase() === 'l') {
      return quantity * 1000;
    }
    return quantity;
  }

  /**
   * Check if the unit is for liquids or solids and then return 'ml' or 'g' respecitvely.
   * @param unit
   */
  public checkIfLiquidOrSolid(unit: string): string {
    if (unit === null || unit === undefined) return unit;
    if (
      unit.toLowerCase() === 'g' ||
      unit.toLowerCase() === 'gr' ||
      unit.toLowerCase() === 'grm' ||
      unit.toLowerCase() === 'gram' ||
      unit.toLowerCase() === 'gramm'
    ) {
      return 'g';
    } else if (unit.toLowerCase() === 'ml' || unit.toLowerCase() === 'mlt') {
      return 'ml';
    } else if (unit.toLowerCase() === 'kg' || unit.toLowerCase() === 'kgm') {
      return 'kg';
    } else if (unit.toLowerCase() === 'l' || unit.toLowerCase() === 'lt') {
      return 'l';
    }
    return unit;
  }

  /**
   * Signals that the event has been updated
   */
  public eventUpdated: Subject<boolean> = new Subject();

  /**
   * Parse string (usually recipe title) and replace whitespace (or everything else non-alphanumerical) with '-'
   * @param title
   */
  public parseStringForLink(title: string): string {
    let urlTitle = title.toLowerCase();
    urlTitle = urlTitle.replace(/ /g, '-');
    urlTitle = urlTitle.replace(/[^\w-]+/g, '');
    return urlTitle;
  }

  /**
   * Sub level that propagates weight from recipe tree view. We need to know this information
   * to prevent sub ingredients from propagating weight.
   */
  public proapgationLevel: number;
  public toggledBox: Subject<boolean> = new Subject();

  //**sroll methods for sidebar */
  public transformSidebarY: number;
  public transformSidebarYExp: string = 'translateY(150)';
  public isOverflown(element?: HTMLElement) {
    if (!element) {
      return false;
    }
    return element.scrollHeight > window.innerHeight;
  }

  public transformExpSet(sidebar: any) {
    this.transformSidebarYExp = 'translate(0, ' + (-300 - (sidebar.scrollHeight - window.innerHeight) - 20) + 'px)';
    sidebar.style.overflowY = 'visible';
  }
  public transformSetDefault(sidebar: any) {
    this.closedNavbarHeader ? (this.transformSidebarY = -300) : (this.transformSidebarY = -150);
    this.transformSidebarYExp = 'translate(0, ' + this.transformSidebarY + 'px)';

    sidebar.style.overflowY = 'visible';
  }
  public scrollHelpMethod(page: string, phase?: string) {
    setTimeout(() => {
      let footer = document.querySelector('#footer-wrapper') as HTMLElement;
      if (footer && window) {
        let diff = footer.offsetTop - window.pageYOffset;
        let sidebar = document.querySelector('.sidebar') as HTMLElement;
        if (sidebar) {
          let contentElement;
          if (page === 'finances') {
            contentElement = document.querySelector('.finances-page-content-left') as HTMLElement;
          } else if (page === 'meals') {
            contentElement = document.querySelector('.meals-page-content-left') as HTMLElement;
          } else if (page === 'drinks') {
            contentElement = document.querySelector('.drinks-page-content-left') as HTMLElement;
          } else {
            contentElement = document.querySelector('.equipment-page-content-left') as HTMLElement;
          }
          let scrollNot = !this.isOverflown(contentElement);
          if (scrollNot && phase === 'start') {
            this.editMode
              ? (sidebar.style.height =
                  sidebar.scrollHeight > window.innerHeight
                    ? sidebar.scrollHeight - sidebar.offsetTop + 100 + 60 + 'px'
                    : sidebar.scrollHeight + 40 + 'px')
              : (sidebar.style.height =
                  sidebar.scrollHeight > window.innerHeight ? 100 + '%' : window.innerHeight - 190 + 'px');
            if (contentElement) {
              contentElement.scrollHeight < sidebar.scrollHeight - 30 && !this.editMode
                ? (sidebar.style.overflowY = 'auto')
                : (sidebar.style.overflowY = 'visible');
            } else {
              sidebar.style.overflowY = 'auto';
            }
            if (page === 'finances') {
              this.editMode
                ? (sidebar.style.height =
                    sidebar.scrollHeight > window.innerHeight
                      ? sidebar.scrollHeight - sidebar.offsetTop - 100 + 60 + 'px'
                      : sidebar.scrollHeight + 40 + 'px')
                : (sidebar.style.height =
                    sidebar.scrollHeight > window.innerHeight
                      ? sidebar.scrollHeight - sidebar.offsetTop + 'px'
                      : window.innerHeight - 190 + 'px');
            }
          } else {
            if (page === 'finances') {
            }
            sidebar.style.overflowY = 'visible';
            if (!this.editMode) {
              if ((diff < 645 || diff < 960) && contentElement) {
                /** put >= if needed */
                if (
                  sidebar.scrollHeight > window.innerHeight ||
                  contentElement.scrollHeight + 200 > window.innerHeight
                ) {
                  this.transformExpSet(sidebar);
                }
              } else {
                this.transformSetDefault(sidebar);
              }
            } else {
              if (diff < 645 || diff < 1000) {
                /** put >= if needed */
                if (sidebar.scrollHeight > window.innerHeight) {
                  this.transformExpSet(sidebar);
                }
              } else {
                this.transformSetDefault(sidebar);
              }
            }
          }
        }
      }
    });
  }

  /** subjects for one filter clear */
  public selectedFiltersChanged: Subject<any> = new Subject();
  public clearOneFilterSubject: Subject<any> = new Subject();

  /** subject for clearing all filters */
  public clearAllFilters: Subject<boolean> = new Subject();

  /** subject for passing date */
  public dateRangeSubject: Subject<string> = new Subject();
  public clearDateRangeFilterClasses: Subject<boolean> = new Subject();

  /** clear blacklist location */
  public clearFilterBlacklistLocationSubject: Subject<boolean> = new Subject();

  /** detect when the finish of create or edit event is happened */
  public registerEventFinished: Subject<boolean> = new Subject();

  /** detect whether is event sent to production plan */
  public addedEventToCreateMenuPlan: Subject<EventCatering> = new Subject();
  /** filter for date range variable, it should stay active until user clears it */
  public dateRangePassed: string;

  /** filter data for events list, saved for reuse after navigating inside events */
  public filterData = {
    field: 'timeBegin',
    from: '1970-01-01 01:00',
    to: '2051-01-01 01:00',
    string: '',
    eventLocation: [] as any[],
    eventCustomer: [] as any[],
    status: [] as any[],
    eventType: [] as any[],
    serviceType: [] as any[],
    costCenter: [] as any[]
  };

  // variable for new way of not losing items that are filtered (in-out of event)
  public itemsFiltered: any[] = [];

  /** Checks if unit is a base unit (l, g, ml, ...) */
  public checkIfBaseUnit(unit: string): boolean {
    if (
      unit.toLowerCase() === 'kg' ||
      unit.toLowerCase() === 'g' ||
      unit.toLowerCase() === 'gr' ||
      unit.toLowerCase() === 'grm' ||
      unit.toLowerCase() === 'gram' ||
      unit.toLowerCase() === 'gramm' ||
      unit.toLowerCase() === 'l' ||
      unit.toLowerCase() === 'ml' ||
      unit.toLowerCase() === 'mlt'
    ) {
      return true;
    }
    return false;
  }

  public ingredientPortionCoefficient: number;
  public ingredientPortionCoefficients: number[] = [];

  public searchStringSettings: Subject<string | String> = new Subject();
  public searchEnabled: boolean;

  public addingRecipe: boolean;

  public switchedDisplayUnit: Subject<string> = new Subject();

  public viewStateChanged: Subject<RecipeDetailsViewState> = new Subject();
}
