import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { IUser } from '../../shared/auth/user';
import { UrlService } from './url.service';

@Injectable()
export class RequestService {
  constructor(private http: HttpClient, private router: Router, private urlService: UrlService) {}

  auth(url: string, data: unknown, headerss?: HttpHeaders): Observable<IUser> {
    return this.http.post<IUser>(url, data, { headers: headerss, withCredentials: true });
  }

  get<R>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<R> {
    return this.http.get<R>(url, options);
  }

  getText(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType: 'text';
      withCredentials?: boolean;
    }
  ): Observable<string> {
    return this.http.get(url, options);
  }

  post<T>(
    url: string,
    body: unknown | null,
    options?: { headers?: HttpHeaders; params?: HttpParams; reportProgress?: boolean }
  ): Observable<T> {
    return this.http.post<T>(url, body || null, options);
  }

  put<T>(
    url: string,
    body: unknown,
    options?: {
      headers?: HttpHeaders;
      params?: HttpParams | { [param: string]: string | string[] };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  delete<T>(url: string, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  protected getDefaultHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Content-Type', 'application/json; charset=utf-8');
  }
}
