import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from 'src/app/shared/util/url.class';
import { CallbackFunction } from 'src/app/shared/services/callback.function';
import { AbstractService } from 'src/app/shared/util/abstract.service';

/**
 * Created by angus on 20.03.16.
 */
@Injectable()
export class ErpService extends AbstractService {
  private injector: Injector;

  constructor(injector: Injector, private http: HttpClient) {
    super(injector);
    const name = 'erp';
    this.injector = injector;
  }

  getAllAvailable<IErp>(clb: CallbackFunction<IErp[]>, error?: (error: any) => void, complete?: () => void) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('erp');
    url.push('all');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  getAvailables<IErp>(clb: CallbackFunction<IErp[]>, error?: (error: any) => void, complete?: () => void) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('erp');
    url.push('available');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  setLastUsed<IErp>(erpId: number, clb: CallbackFunction<IErp>, error?: (error: any) => void, complete?: () => void) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('erp');
    url.push('last-used');
    url.push(erpId);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }
}
