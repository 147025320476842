import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { NotifyModalComponent } from './notify-modal/notify-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { TwoOptionsModalComponent } from './two-options-modal/two-options-modal.component';
import { IInputError, IRestError } from '../../interface/rest-error.interface';

@Injectable()
export class CommonModalService {
  errorModalRef: BsModalRef;

  bindingErrorModalRef: BsModalRef;

  warningModalRef: BsModalRef;

  confirmModalRef: BsModalRef;

  private errorModalSubscrition: Subscription;

  private bindingErrorModalSubscrition: Subscription;

  private warrningModalSubscrition: Subscription;

  private confirmModalSubscrition: Subscription;
  public onClose: Subject<boolean> = new Subject();

  constructor(private modalService: BsModalService) {}

  showErrorModal(httpErrorResponse: HttpErrorResponse, showMe?: boolean, forceMe?: boolean): void {
    console.log('httpErrorResponse ', httpErrorResponse);
    if (httpErrorResponse.status === 401) {
      return;
    }

    if (!this.decideAndPrepareShowing(4, showMe !== undefined ? showMe : true, forceMe)) {
      return;
    }

    if (!this.errorModalSubscrition) {
      if (!httpErrorResponse.error) {
        let message = '';
        if (!httpErrorResponse.status) {
          message = httpErrorResponse.message;
        } else {
          message = `${httpErrorResponse.status.toString()}\n\n${httpErrorResponse.message}`;
        }

        const modalOptions = {
          initialState: {
            message,
            caption: 'cpt.Error_message',
            closeBtnName: 'lbl.Close'
          },
          class: 'error'
        };
        this.errorModalRef = this.modalService.show(NotifyModalComponent, modalOptions);
      } else {
        const modalOptions = {
          initialState: {
            message: 'Unknown error message',
            caption: 'cpt.Error_message',
            closeBtnName: 'lbl.Close',
            captionDesc: '',
            developerMessage: ''
          },
          class: 'error'
        };

        if (httpErrorResponse.error.errors) {
          modalOptions.initialState.message = httpErrorResponse.error.errors[0];
        } else if (httpErrorResponse.error.message) {
          const err = httpErrorResponse.error as IRestError;
          modalOptions.initialState.message = (err.status ? `${err.status.toString()}\n` : '') + err.message;
          modalOptions.initialState.captionDesc = err.title;
          modalOptions.initialState.developerMessage = err.developerMessage;
        }

        this.errorModalRef = this.modalService.show(NotifyModalComponent, modalOptions);
      }

      this.errorModalSubscrition = this.modalService.onHidden.subscribe(() => {
        this.errorModalSubscrition.unsubscribe();
        delete this.errorModalSubscrition;
        if (httpErrorResponse.error.status === 'token_expired') {
          this.onClose.next(true);
        }
      });
    }
  }

  showBindingErrorModal(
    httpErrorResponse: HttpErrorResponse,
    unhandledError: IInputError[],
    showMe?: boolean,
    forceMe?: boolean
  ): void {
    if (!this.decideAndPrepareShowing(4, showMe, forceMe)) {
      return;
    }

    if (!this.bindingErrorModalSubscrition) {
      let msg = '';

      unhandledError.forEach((value: IInputError) => {
        msg += `\n${value.ref} -> ${value.msg}`;
      });
      const err = httpErrorResponse.error as IRestError;
      const modalOptions = {
        initialState: {
          message: `${err.status}\n${msg}`,
          caption: 'cpt.Error_message',
          captionDesc: err.title,
          developerMessage: err.developerMessage,
          closeBtnName: 'lbl.Close'
        },
        class: 'error'
      };

      this.bindingErrorModalRef = this.modalService.show(NotifyModalComponent, modalOptions);

      this.bindingErrorModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.bindingErrorModalSubscrition.unsubscribe();
        delete this.bindingErrorModalSubscrition;
      });
    }
  }

  showWarningModal(caption: string, msg: string, showMe?: boolean, forceMe?: boolean): void {
    if (!this.decideAndPrepareShowing(4, showMe, forceMe)) {
      return;
    }

    if (!this.warrningModalSubscrition) {
      const modalOptions = {
        initialState: {
          message: msg,
          caption,
          closeBtnName: 'lbl.Close'
        },
        class: 'warning'
      };

      this.warningModalRef = this.modalService.show(NotifyModalComponent, modalOptions);

      this.warrningModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.warrningModalSubscrition.unsubscribe();
        delete this.warrningModalSubscrition;
      });
    }
  }

  showYesNoModal(msg: string): Subject<boolean> {
    if (!this.confirmModalSubscrition) {
      const modalOptions = {
        initialState: {
          message: msg
        },
        class: 'confirm'
      };

      this.confirmModalRef = this.modalService.show(ConfirmModalComponent, modalOptions);

      this.confirmModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.confirmModalSubscrition.unsubscribe();
        delete this.confirmModalSubscrition;
      });

      return (this.confirmModalRef.content as ConfirmModalComponent).onClose;
    }

    return undefined;
  }

  showDeleteCancelModal(msg: string): Subject<boolean> {
    if (!this.confirmModalSubscrition) {
      const modalOptions = {
        initialState: {
          message: msg,
          confirmLabel: 'lbl.Delete',
          cancelLabel: 'lbl.Cancel'
        },
        class: 'warning'
      };

      this.confirmModalRef = this.modalService.show(ConfirmModalComponent, modalOptions);

      this.confirmModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.confirmModalSubscrition.unsubscribe();
        delete this.confirmModalSubscrition;
      });

      return (this.confirmModalRef.content as ConfirmModalComponent).onClose;
    }

    return undefined;
  }

  showConfirmModal(msg: string, caption?: string): Subject<boolean> {
    if (!this.confirmModalSubscrition) {
      const modalOptions = {
        initialState: {
          caption: caption,
          message: msg,
          confirmLabel: 'lbl.OK',
          cancelLabel: 'lbl.Cancel'
        },
        class: 'confirm'
      };
      this.confirmModalRef = this.modalService.show(ConfirmModalComponent, modalOptions);

      this.confirmModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.confirmModalSubscrition.unsubscribe();
        delete this.confirmModalSubscrition;
      });

      return (this.confirmModalRef.content as ConfirmModalComponent).onClose;
    }
    return undefined;
  }

  showTwoOptionsModal(
    cpt: string,
    msg: string,
    ol1: string,
    ol2: string,
    cancel?: string,
    cls?: string
  ): Subject<number> {
    if (!this.confirmModalSubscrition) {
      const is = {
        caption: cpt,
        message: msg,
        option1Label: ol1,
        option2Label: ol2
      };

      if (cancel) {
        Object.assign(is, { cancelLabel: cancel });
      }

      const modalOptions = {
        initialState: is,
        class: `confirm${cls ? ` ${cls}` : ''}`
      };

      this.confirmModalRef = this.modalService.show(TwoOptionsModalComponent, modalOptions);

      this.confirmModalSubscrition = this.modalService.onHidden.subscribe((/* reason: string */) => {
        this.confirmModalSubscrition.unsubscribe();
        delete this.confirmModalSubscrition;
      });

      return (this.confirmModalRef.content as TwoOptionsModalComponent).onClose;
    }

    return undefined;
  }

  /**
   *
   * @param priority priority of caller
   * @param showMe  if there is opend modal, check priority
   * @param forceMe  if priority is equel what to do
   */
  decideAndPrepareShowing(priority: number, showMe: boolean, forceMe: boolean): boolean {
    const omp = this.getOpenedModalPriority();
    if (showMe) {
      if (priority > omp || (priority === omp && forceMe)) {
        this.closeAllModals();
        return true;
      }
    } else if (omp === 0) {
      return true;
    }

    return false;
  }

  closeAllModals(): void {
    if (this.errorModalSubscrition) {
      this.errorModalRef.hide();
    }

    if (this.bindingErrorModalSubscrition) {
      this.bindingErrorModalRef.hide();
    }

    if (this.warrningModalSubscrition) {
      this.warningModalRef.hide();
    }

    if (this.confirmModalSubscrition) {
      this.confirmModalRef.hide();
    }
  }

  getOpenedModalPriority(): number {
    if (this.errorModalSubscrition) {
      return 4;
    }

    if (this.bindingErrorModalSubscrition) {
      return 3;
    }

    if (this.warrningModalSubscrition) {
      return 2;
    }

    if (this.confirmModalSubscrition) {
      return 1;
    }

    return 0;
  }
}
