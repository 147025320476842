import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { RequestService } from 'src/app/core/services/request.service';
import { UrlService } from 'src/app/core/services/url.service';
import { AppLocaleStorageService } from 'src/app/shared/util/local-storage.service';
import { FormsModule } from '@angular/forms';
import { CommunicationService } from './services/communication.service';
import { ProfileStateService } from './services/profile.state.service';
import { DashboardService } from '../panel/dashboard/services/entity-service/dashboard.service';
import { LocationService } from '../panel/catering/services/location.service';
import { EventCateringService } from '../panel/catering/services/event-catering.service';
import { MealsService } from '../panel/catering/services/meals.service';
import { DrinksService } from '../panel/catering/services/drinks.service';
import { EquipmentService } from '../panel/catering/services/equipment.service';
import { EventCateringCommunicationService } from '../panel/catering/services/event-catering-communication.service';
import { CommonModalService } from '../shared/bootstrap/modal/common-modal.service';
import { NotifyModalComponent } from '../shared/bootstrap/modal/notify-modal/notify-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { ErpSelectedStateService } from '../panel/erp/services/state-service/erp.selected.state.service';
import { ErpService } from '../panel/erp/services/entity-service/erp.service';
import { ErpAvailableStateService } from '../panel/erp/services/state-service/erp.available.state.service';

@NgModule({
  declarations: [NotifyModalComponent],
  imports: [BrowserModule, HttpClientModule, FormsModule, TranslateModule],
  exports: [HttpClientModule, FormsModule],
  providers: [
    AuthenticationService,
    RequestService,
    UrlService,
    AppLocaleStorageService,
    CommunicationService,
    ProfileStateService,
    LocationService,
    DashboardService,
    EventCateringService,
    MealsService,
    EventCateringCommunicationService,
    CommonModalService,
    DrinksService,
    EquipmentService,
    ErpSelectedStateService,
    ErpService,
    ErpAvailableStateService
  ]
})
export class CoreModule {}
