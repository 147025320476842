import { Injectable } from '@angular/core';
import { Url } from '../../shared/util/url.class';

/**
 * Created by amu on 23.05.2018.
 */
@Injectable()
export class UrlService {
  private appBaseHref: string;

  constructor() {}

  public get baseUrl(): Url {
    const url = new Url();
    return url;
  }

  public get baseApiUrl(): Url {
    const url = new Url();
    url.push('api');
    return url;
  }

  public get baseAppUrl(): Url {
    const url = new Url();
    url.push('app');
    return url;
  }
}
