import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IUser } from 'src/app/shared/auth/user';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { url } = state;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    if (this.redirect()) {
      return of(false);
    }
    return this.authenticationService.checkServerAuthentication(url).pipe(
      map(() => {
        this.redirect();
        return false;
      }),
      catchError((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        return of(false);
      })
    );
  }

  private redirect(): boolean {
    if (this.authenticationService.redirectRoute && this.authenticationService.redirectRoute !== '/') {
      this.router.navigate([this.authenticationService.getAndClearRedirectRoute()]);
      return true;
    }
    const user = this.authenticationService.getUser() as IUser;
    /** TODO CHANGE TO supplier FROM localhost */
    if (location.host.includes('supplier')) {
      if (user && user.roles.includes('ROLE_SUPPLIER')) {
        this.router.navigate(['/supplier']);
        return true;
      } else {
        return false;
      }
    }
    if (user) {
      this.router.navigate(['/panel']);
      return true;
    }
    return false;
  }
}
