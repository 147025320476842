<div class="container-fluid px-0 background-style">
    <div class="row center-content h-100">
        <div class="col-lg-6 col-xs-12">
            <div class="col-lg-10 col-xs-12 center-content">
                <img src="/assets/login-page/logo.svg" />
            </div>
        </div>
        <div class="col-lg-6 col-xs-12 form-positioning">
            <div class="row flex-column form-content-positioning">
                <div class="lang-switch-wrapper font-color-switch">
                    <p class="option" [class.active]="language === 'de'" (click)="languageChange('de')">
                        {{ 'shared.loginPage.lang.german' | translate }}
                    </p>
                    <p class="option" [class.active]="language === 'en'" (click)="languageChange('en')">
                        {{ 'shared.loginPage.lang.english' | translate }}
                    </p>
                </div>
                <form (ngSubmit)="login()" #loginForm="ngForm">
                    <h6 *ngIf="!loginForm.hasError('custom')" class="font-color-switch title-style">
                        {{ 'shared.loginPage.messages.title' | translate }}
                    </h6>
                    <h6 *ngIf="loginForm.hasError('custom')" class="font-color-switch title-style validationFailed-msg">
                        {{ 'shared.loginPage.validationMessages.wrongEmailOrPassword' | translate }}
                    </h6>
                    <div class="form-group inputField-group">
                        <div class="inputField-group-icon">
                            <img src="/assets/supplier/envelope.svg" />
                        </div>
                        <input
                            type="email"
                            id="username"
                            name="username"
                            #username="ngModel"
                            [(ngModel)]="user.username"
                            class="form-control input-style"
                            [class.validation-error]="
                                (username.touched && username.invalid) || loginForm.hasError('custom')
                            "
                            [class.wrongEmailFormat-padding-en]="
                                username.touched && username.hasError('email') && language === 'en'
                            "
                            [class.wrongEmailFormat-padding-de]="
                                username.touched && username.hasError('email') && language === 'de'
                            "
                            [class.noMatch-padding]="loginForm.hasError('custom')"
                            placeholder="{{ 'shared.loginPage.placeholders.email' | translate }}"
                            autocomplete="off"
                            autofocus
                            required
                            email
                        />
                        <div *ngIf="username.hasError('email')" class="inputField-group-message">
                            {{ 'shared.loginPage.validationMessages.wrongEmailFormat' | translate }}
                        </div>
                        <div *ngIf="username.touched && username.hasError('required')" class="inputField-group-message">
                            {{ 'shared.loginPage.validationMessages.mandatory' | translate }}
                        </div>
                        <div *ngIf="loginForm.hasError('custom')" class="inputField-group-message">
                            {{ loginForm.getError('custom') | translate }}
                        </div>
                    </div>
                    <div class="form-group inputField-group">
                        <div class="inputField-group-icon">
                            <img src="/assets/supplier/key.svg" />
                        </div>
                        <input
                            [type]="passwordInputType ? 'password' : 'text'"
                            id="password"
                            name="password"
                            #password="ngModel"
                            [(ngModel)]="user.password"
                            class="form-control input-style passwordEye-padding"
                            [class.validation-error]="
                                (password.touched && password.invalid) || loginForm.hasError('custom')
                            "
                            [class.noMatch-padding]="loginForm.hasError('custom')"
                            placeholder="{{ 'shared.loginPage.placeholders.password' | translate }}"
                            autocomplete="off"
                            required
                        />
                        <div
                            *ngIf="!passwordInputType"
                            class="fa fa-eye inputField-group-passwordIcon"
                            (click)="showHidePassword()"
                        ></div>
                        <div
                            *ngIf="passwordInputType"
                            class="fa fa-eye-slash inputField-group-passwordIcon"
                            (click)="showHidePassword()"
                        ></div>
                        <div *ngIf="password.touched && password.hasError('required')" class="inputField-group-message">
                            {{ 'shared.loginPage.validationMessages.mandatory' | translate }}
                        </div>
                        <div *ngIf="loginForm.hasError('custom')" class="inputField-group-message">
                            {{ loginForm.getError('custom') | translate }}
                        </div>
                    </div>
                    <div class="form-group form-check rememberMe-style">
                        <input
                            id="rememberMe"
                            name="rememberMe"
                            #rememberMe="ngModel"
                            [(ngModel)]="user.rememberMe"
                            type="checkbox"
                            class="form-check-input"
                        />
                        <label class="form-check-label font-color-switch" for="rememberMe">
                            {{ 'shared.loginPage.buttons.rememberMe' | translate }}
                        </label>
                    </div>
                    <button
                        type="submit"
                        class="login-btn"
                        [disabled]="loading || (loginForm.invalid && loginForm.touched)"
                        [class.button-opacity]="loginForm.touched && loginForm.invalid"
                    >
                        {{ 'shared.loginPage.buttons.login' | translate }}
                    </button>
                </form>
                <div class="password-wrapper">
                    <a class="font-color-switch" href="/reset-password{{ language ? '?_locale=' + language : '' }}">
                        {{ 'shared.loginPage.messages.forgottenPassword' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
