import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonModalService } from 'src/app/shared/bootstrap/modal/common-modal.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(public router: Router, private commonModalService: CommonModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let handled: boolean = false;
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            switch (error.status) {
              case 401: //login
                /** TODO CHANGE TO supplier FROM localhost */
                if (location.host.includes('supplier')) {
                  this.router.navigateByUrl('/supplier/login');
                } else {
                  this.router.navigateByUrl('/login');
                }
                handled = true;
                break;
              case 403: //forbidden
                if (req.url !== 'signin_check') {
                  this.commonModalService.showErrorModal(error);
                  handled = true;
                } else {
                  handled = false;
                }
                break;
            }
          }
        } else {
          console.error('Other Errors');
        }

        if (handled) {
          return of(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
