import { Injectable } from '@angular/core';
import { RequestService } from '../../core/services/request.service';
import { Url } from '../util/url.class';
import { UrlService } from '../../core/services/url.service';
import { IVersion } from './version.class';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private version: IVersion;

  private versionString: string;

  constructor(private urlService: UrlService, private requestService: RequestService) {}

  refresh(): void {
    const url: Url = this.urlService.baseApiUrl;
    url.push('pub');
    url.push('version');
    this.requestService.get<IVersion>(url.serialize()).subscribe((data: IVersion) => {
      this.version = data;
      // eslint-disable-next-line no-console
      console.log(this.version);
      this.versionString = `v${this.version.version}`;
      if (this.version.gitBranchName !== 'release') {
        this.versionString += `-${this.version.gitHash}`;
      }
    });
  }

  get(): IVersion {
    return this.version;
  }

  getVersionString(): string {
    return this.versionString;
  }
}
