<div class="container-fluid px-0 background-style">
    <div class="row center-content h-100">
        <div class="col-lg-6 col-xs-12">
            <div class="col-lg-10 col-xs-12 center-content">
                <img src="/assets/login-page/logo.svg" />
            </div>
        </div>
        <div class="col-lg-6 col-xs-12 form-positioning">
            <div class="row flex-column form-content-positioning">
                <div class="lang-switch-wrapper font-color-switch">
                    <p class="option" [class.active]="language === 'de'" (click)="languageChange('de')">
                        {{ 'shared.loginPage.lang.german' | translate }}
                    </p>
                    <p class="option" [class.active]="language === 'en'" (click)="languageChange('en')">
                        {{ 'shared.loginPage.lang.english' | translate }}
                    </p>
                </div>
                <form (ngSubmit)="reset()" #resetPasswordForm="ngForm">
                    <h6 *ngIf="!resetPasswordForm.hasError('custom')" class="font-color-switch title-style">
                        {{ 'shared.resetPasswordPage.messages.title' | translate }}
                    </h6>
                    <h6
                        *ngIf="resetPasswordForm.hasError('custom')"
                        class="font-color-switch title-style validationFailed-msg"
                    >
                        {{ 'shared.resetPasswordPage.validationMessages.wrongEmail' | translate }}
                    </h6>
                    <div class="form-group inputField-group" *ngIf="!complete">
                        <div class="inputField-group-icon">
                            <img src="/assets/supplier/envelope.svg" />
                        </div>
                        <input
                            type="email"
                            id="username"
                            name="username"
                            #username="ngModel"
                            [(ngModel)]="user.username"
                            class="form-control input-style"
                            [class.validation-error]="
                                (username.touched && username.invalid) || resetPasswordForm.hasError('custom')
                            "
                            [class.wrongEmailFormat-padding-en]="
                                username.touched && username.hasError('email') && language === 'en'
                            "
                            [class.wrongEmailFormat-padding-de]="
                                username.touched && username.hasError('email') && language === 'de'
                            "
                            [class.noMatch-padding]="resetPasswordForm.hasError('custom')"
                            placeholder="{{ 'shared.loginPage.placeholders.email' | translate }}"
                            autocomplete="off"
                            autofocus
                            required
                            email
                        />
                        <div *ngIf="username.hasError('email')" class="inputField-group-message">
                            {{ 'shared.loginPage.validationMessages.wrongEmailFormat' | translate }}
                        </div>
                        <div *ngIf="username.touched && username.hasError('required')" class="inputField-group-message">
                            {{ 'shared.loginPage.validationMessages.mandatory' | translate }}
                        </div>
                        <div *ngIf="resetPasswordForm.hasError('custom')" class="inputField-group-message">
                            {{ resetPasswordForm.getError('custom') | translate }}
                        </div>
                    </div>
                    <div class="complete" *ngIf="complete">
                        {{ 'shared.resetPasswordPage.messages.success' | translate }}
                    </div>

                    <button
                        *ngIf="!complete"
                        type="submit"
                        class="reset-btn"
                        [disabled]="loading || (resetPasswordForm.invalid && resetPasswordForm.touched)"
                        [class.button-opacity]="resetPasswordForm.touched && resetPasswordForm.invalid"
                    >
                        {{ 'shared.resetPasswordPage.buttons.text' | translate }}
                    </button>
                    <a
                        href="/login{{ language ? '?_locale=' + language : '' }}"
                        *ngIf="complete"
                        class="reset-btn back-button"
                    >
                        {{ 'shared.resetPasswordPage.messages.backToLogin' | translate }}
                    </a>
                </form>
                <div class="password-wrapper">
                    <a
                        *ngIf="!complete"
                        class="font-color-switch"
                        href="/login{{ language ? '?_locale=' + language : '' }}"
                    >
                        {{ 'shared.resetPasswordPage.messages.backToLogin' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
