import { Injectable, Injector } from '@angular/core';
import { IErp } from '../../entity/erp';
import { Observable, BehaviorSubject } from 'rxjs';
import { ErpAvailableStateService } from './erp.available.state.service';
import { ErpService } from '../entity-service/erp.service';
import { AppLocaleStorageService } from 'src/app/shared/util/local-storage.service';

@Injectable()
export class ErpSelectedStateService {
  public selectedErp: IErp;
  public changeObeservable: Observable<IErp>;
  private _changeSubject: BehaviorSubject<IErp> = new BehaviorSubject<IErp>(null);
  private erpAvailableStateService: ErpAvailableStateService;

  constructor(injector: Injector, private alss: AppLocaleStorageService) {
    this.changeObeservable = this._changeSubject.asObservable();
    const erpService = injector.get(ErpService);
    this.erpAvailableStateService = injector.get(ErpAvailableStateService);

    if (!this.selectedErp) {
      erpService.getAvailables((e: IErp[]) => {
        this.erpAvailableStateService.setAvailables(e);

        const lastId: number = Number(alss.getItem('erp.selectedErp'));
        this.alss.removeItem('erp.selectedErp');

        const lastCC = e.find((ee) => ee.id === lastId);
        if (lastCC) {
          this.select(lastCC);
        } else {
          this.select(e[0]);
        }
      });
    }
  }

  select(erp: IErp) {
    this.selectedErp = Object.assign({}, erp);
    this.alss.setItem('erp.selectedErp', this.selectedErp.id.toString());
    this._changeSubject.next(this.selectedErp);
  }

  getSelectedErpId() {
    if (this.selectedErp) {
      return this.selectedErp.id;
    } else {
      return Number(this.alss.getItem('erp.selectedErp'));
    }
  }

  subscribe(clb: (e: IErp) => void) {
    this._changeSubject.subscribe(clb);
  }

  observeSelected() {
    return this.changeObeservable;
  }
}
