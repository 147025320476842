<div class="container-fluid background-style" [ngClass]="this.theme == 'light' ? 'light' : 'dark'">
    <div class="container w-100 h-100">
        <div class="justify-content-center row h-100 align-items-center m-0">
            <div class="plate-img col-9 col-lg-5 h-50"></div>
            <div class="col-10 col-lg-7 text-center text-lg-left p-0">
                <div class="ops-message">{{ 'shared.notFoundPage.opsMessage' | translate }}</div>
                <div class="apologies">{{ 'shared.notFoundPage.apologies' | translate }}</div>
                <div>
                    <a
                        href="/panel/dashboard/"
                        class="btn homepage-btn"
                        [ngClass]="this.theme == 'light' ? 'light-btn' : 'dark-btn'"
                    >
                        {{ 'shared.notFoundPage.goToHomepage' | translate }}
                        <img *ngIf="this.theme == 'dark'" [src]="'/assets/not-found/arrow-icon.svg'" />
                        <img *ngIf="this.theme == 'light'" [src]="'/assets/not-found/arrow-icon-light.svg'" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
