import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-boson-approve',
  templateUrl: './two-options-modal.component.html'
})
export class TwoOptionsModalComponent implements OnInit {
  caption = 'txt.Choos_option';

  message = '';

  option1Label = 'txt.Option_1';

  option2Label = 'txt.Option_2';

  cancelLabel = 'lbl.Cancel';

  option1 = 'Option 1';

  option2 = 'Option 2';

  cancel = 'Cancel';

  public onClose: Subject<number>;

  constructor(public bsModalRef: BsModalRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    this.translateService.get(this.option1Label).subscribe((res: string) => {
      this.option1 = res;
    });

    this.translateService.get(this.option2Label).subscribe((res: string) => {
      this.option2 = res;
    });

    this.translateService.get(this.cancelLabel).subscribe((res: string) => {
      this.cancel = res;
    });
  }

  public onOption1(): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(() => {
      this.onClose.next(1);
    });
  }

  public onOption2(): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(() => {
      this.onClose.next(2);
    });
  }

  public onCancel(): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(() => {
      this.onClose.next(0);
    });
  }
}
