import { Observable } from 'rxjs';
import { Injector } from '@angular/core';
import { RequestService } from '../../core/services/request.service';
import { UrlService } from '../../core/services/url.service';
import { CallbackFunction } from '../interface/callback-function.interface';

export abstract class AbstractService {
  protected requestService: RequestService;
  protected urlService: UrlService;

  constructor(injector: Injector) {
    this.urlService = injector.get(UrlService);
    this.requestService = injector.get(RequestService);
  }

  protected _subscribe<T>(
    observable: Observable<any>,
    clb: CallbackFunction<T>,
    errorClb?: CallbackFunction<any>,
    completeClb?: () => void,
    parsePayload: boolean = true
  ) {
    return observable.subscribe({
      next: (e) => {
        const data = parsePayload && e.payload !== undefined ? e.payload : e;
        clb(data);
      },
      error: (e) => {
        if (errorClb) {
          errorClb(e);
        }
      },
      complete: () => {
        if (completeClb) {
          completeClb();
        }
      }
    });
  }
}
