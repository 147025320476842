import { Injectable, Injector } from '@angular/core';
import { Drink } from '../interfaces/drink.interface';
import { IResponse } from '../interfaces/response.interface';
import { DrinkGroup } from '../interfaces/drink-group.interface';
import { Subject } from 'rxjs';
import { AbstractService } from 'src/app/shared/util/abstract.service';
import { CallbackFunction } from 'src/app/shared/interface/callback-function.interface';
import { IErrorServerResponse } from 'src/app/shared/interface/error.server.response.interface';
import { Url } from 'src/app/shared/util/url.class';

@Injectable()
export class DrinksService extends AbstractService {
  public drinksGroup: DrinkGroup[] = [];
  public drinksGroupChanged: Subject<boolean> = new Subject();
  public drinkGroupClicked: Subject<DrinkGroup> = new Subject<DrinkGroup>();
  public drinkGroupTotals: number[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  public getEventDrinks(
    eventId: string,
    clb: CallbackFunction<DrinkGroup[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push('event');
    url.push(eventId);
    url.put('orderBy', 'index');
    url.put('orderDirection', 'ASC');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getDrink(
    id: string,
    clb: CallbackFunction<Drink>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push(id);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public addEventDrink(
    data: { eventId: number; eventRecipeId: number; persons: number; perPerson: number; index: number },
    clb: CallbackFunction<Drink>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    this._subscribe(
      this.requestService.post(url.serialize(), {
        event: data.eventId.toString(),
        eventRecipe: data.eventRecipeId.toString(),
        persons: data.persons.toString(),
        perPerson: data.perPerson.toString(),
        index: data.index.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public updateDrink(
    drinkId: string,
    data: any,
    clb: CallbackFunction<Drink>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push(drinkId);
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateDrinkGroup(
    groupId: string,
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    url.push(groupId);
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateDrinkOrder(
    data: any,
    clb: CallbackFunction<IResponse<Drink>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateDrinkGroupsOrder(
    data: any,
    clb: CallbackFunction<IResponse<DrinkGroup[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateDrinkSubgroupsOrder(
    data: any,
    clb: CallbackFunction<IResponse<DrinkGroup[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public addDrinkGroup(
    data: any,
    clb: CallbackFunction<IResponse<DrinkGroup>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public addEventDrinkFromRecipe(
    data: {
      eventId: string;
      recipeId: string;
      eventDrinkSubGroupId: string;
      eventDrinkGroupId: string;
      persons: number;
      perPerson: number;
      index: number;
      totalPieces?: number;
    },
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push('createFromRecipe');
    url.push(data.recipeId);
    url.put('catering', true);

    this._subscribe(
      this.requestService.post(url.serialize(), {
        event: data.eventId.toString(),
        persons: data.persons.toString(),
        perPerson: data.perPerson.toString(),
        index: data.index.toString(),
        totalPieces: data.totalPieces.toString(),
        eventDrinkSubgroup: data.eventDrinkSubGroupId.toString(),
        eventDrinkGroup: data.eventDrinkGroupId.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public deleteEventDrink(
    data: { drinkId: number },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrink');
    url.push(data.drinkId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public deleteDrinkGroup(
    data: { drinkGroupId: string },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    url.push(data.drinkGroupId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }
  public searchGroups(
    eventId: string,
    name: string,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    url.push('search');
    this._subscribe(this.requestService.post(url.serialize(), { event: eventId, name: name }), clb, error, complete);
  }

  public searchSubGroups(
    eventId: string,
    name: string,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    url.push('search');
    this._subscribe(this.requestService.post(url.serialize(), { event: eventId, name: name }), clb, error, complete);
  }

  public addSubGroup(
    eventId: string,
    name: string,
    eventDrinkGroupId: string,
    index: number,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    this._subscribe(
      this.requestService.post(url.serialize(), {
        event: eventId,
        name: name,
        eventDrinkGroup: eventDrinkGroupId,
        index: index.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public addSubGroupMultiple(
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    url.push('createMultiple');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public updateDrinkSubgroup(
    id: string,
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    url.push(id);
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public deleteDrinkSubgroup(
    id: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkSubgroup');
    url.push(id);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public copyGroupsFromMeals(
    eventId: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventMealGroup');
    url.push('event');
    url.push(eventId);
    url.push('createDrinkGroups');
    this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public copyGroupsFromEquipment(
    eventId: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push('event');
    url.push(eventId);
    url.push('createDrinkGroups');
    this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }
}
