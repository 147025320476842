import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/core/services/url.service';
import { Url } from 'src/app/shared/util/url.class';

@Injectable()
export class DashboardService {
  public modalPosData: any;
  public modalSuppliersData: any;

  constructor(private http: HttpClient, private urlService: UrlService) {}

  private prepareUrl(path: string): Url {
    const url: Url = this.urlService.baseApiUrl;
    url.push(path);
    return url;
  }

  getBoxesData() {
    const url: Url = this.prepareUrl('dashboard');
    url.push('getBoxesData');
    return this.http.get(url.serialize(), { withCredentials: true });
  }

  getChartData(costCenterId: number) {
    const url: Url = this.prepareUrl('dashboard');
    url.push('getChartData');
    url.push(costCenterId);
    return this.http.get(url.serialize(), { withCredentials: true });
  }

  getWeatherData(costCenterId: number) {
    const url: Url = this.prepareUrl('dashboard');
    url.push('getWeatherData');
    url.push(costCenterId);
    return this.http.get(url.serialize(), { withCredentials: true });
  }

  getAvailableErps() {
    const url: Url = this.prepareUrl('erp');
    url.push('available');
    return this.http.get(url.serialize(), { withCredentials: true });
  }

  getDashboard(costCenterId: number) {
    const url: Url = this.prepareUrl('dashboard');
    url.push('getDashboard');
    url.push(costCenterId);
    return this.http.get(url.serialize(), { withCredentials: true });
  }

  sendUpdatedLayout(layoutArray: Array<unknown>) {
    const url: Url = this.prepareUrl('dashboard');
    url.push('updateLayout');
    return this.http.put(url.serialize(), layoutArray, { withCredentials: true });
  }

  sendCheckedArray(checkedArray: Array<unknown>) {
    const url: Url = this.prepareUrl('dashboard');
    url.push('updateCheckedArray');
    return this.http.put(url.serialize(), checkedArray, { withCredentials: true });
  }

  updateCostCenter(costCenterId: number) {
    const url: Url = this.prepareUrl('shops');
    url.push('updateCostCenter');
    url.push(costCenterId);
    return this.http.put(url.serialize(), { withCredentials: true });
  }

  public getModalPosData() {
    return this.modalPosData;
  }

  public getModalSuppliersData() {
    return this.modalSuppliersData;
  }
}
