import { Injectable, Injector } from '@angular/core';
import { IResponse } from '../interfaces/response.interface';
import { EquipmentGroup } from '../interfaces/equipment-group.interface';
import { Subject } from 'rxjs';
import { AbstractService } from 'src/app/shared/util/abstract.service';
import { CallbackFunction } from 'src/app/shared/interface/callback-function.interface';
import { IErrorServerResponse } from 'src/app/shared/interface/error.server.response.interface';
import { Url } from 'src/app/shared/util/url.class';
import { Equipment } from '../interfaces/equipment.interface';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends AbstractService {
  public equipmentGroup: EquipmentGroup[] = [];
  public equipmentGroupChanged: Subject<boolean> = new Subject();
  public equipmentGroupClicked: Subject<EquipmentGroup> = new Subject<EquipmentGroup>();
  public equipmentGroupPortitions: number[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  public getEventEquipmentGroup(
    eventId: string,
    clb: CallbackFunction<IResponse<EquipmentGroup[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push('event');
    url.push(eventId);
    url.put('orderBy', 'index');
    url.put('orderDirection', 'ASC');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public searchAllEquipmentGroups(
    data: { eventId: string; searchTerm: string; pageSize: number },
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push('search');
    url.put('pageSize', data.pageSize);
    this._subscribe(
      this.requestService.post(url.serialize(), { event: data.eventId, name: data.searchTerm.toLowerCase() }),
      clb,
      error,
      complete
    );
  }

  public searchAllEquipments(
    data: { eventId: string; searchTerm: string; pageSize: number },
    clb: CallbackFunction<Equipment[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push('searchConcreteProducts');
    url.push('event');
    url.push(data.eventId);
    url.put('pageSize', data.pageSize);
    this._subscribe(
      this.requestService.post(url.serialize(), { searchString: data.searchTerm.toLowerCase() }),
      clb,
      error,
      complete
    );
  }

  public addEventEquipment(
    data: any,
    clb: CallbackFunction<Equipment>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');

    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public updateEventEquipment(
    data: { eventEquipmentId?: string; eventEquipmentSubGroupId?: string; quantity?: number },
    clb: CallbackFunction<Equipment>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push(data.eventEquipmentId.toString());

    this._subscribe(
      this.requestService.put(url.serialize(), {
        eventEquipmentSubgroup: data.eventEquipmentSubGroupId.toString(),
        quantity: data.quantity.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public updateEventEquipmentIndex(
    data: { eventEquipmentId: string; eventEquipmentGroupId: string; index: number },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push('index');
    url.push('update');
    url.push(data.eventEquipmentId.toString());

    this._subscribe(
      this.requestService.put(url.serialize(), {
        id: data.eventEquipmentId,
        eventEquipmentGroup: data.eventEquipmentGroupId.toString(),
        index: data.index.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public updateEquipmentOrder(
    data: any,
    clb: CallbackFunction<IResponse<Equipment>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateEquipmentSubgroupsOrder(
    data: any,
    clb: CallbackFunction<IResponse<EquipmentGroup[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateEquipmentGroupsOrder(
    data: any,
    clb: CallbackFunction<IResponse<EquipmentGroup[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push('index');
    url.push('update');
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public updateEquipmentGroup(
    groupId: string,
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push(groupId);
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public addEquipmentGroup(
    data: any,
    clb: CallbackFunction<IResponse<EquipmentGroup>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public addEventEquipmentFromConcrete(
    data: {
      eventId: string;
      recipeId: string;
      eventEquipmentSubGroupId: string;
      persons: number;
      perPerson: number;
      index: number;
      totalPieces?: number;
    },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push('createFromRecipe');
    url.push(data.recipeId);
    url.put('catering', true);

    this._subscribe(
      this.requestService.post(url.serialize(), {
        event: data.eventId.toString(),
        persons: data.persons.toString(),
        perPerson: data.perPerson.toString(),
        index: data.index.toString(),
        totalPieces: data.totalPieces.toString(),
        eventEquipmentSubgroup: data.eventEquipmentSubGroupId.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public copyGroupsFromMeals(
    eventId: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventMealGroup');
    url.push('event');
    url.push(eventId);
    url.push('createEquipmentGroups');
    this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public copyGroupsFromDrinks(
    eventId: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventDrinkGroup');
    url.push('event');
    url.push(eventId);
    url.push('createEquipmentGroups');
    this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public searchGroups(
    eventId: string,
    name: string,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push('search');
    this._subscribe(this.requestService.post(url.serialize(), { event: eventId, name: name }), clb, error, complete);
  }

  public searchSubGroups(
    eventId: string,
    name: string,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    url.push('search');
    this._subscribe(this.requestService.post(url.serialize(), { event: eventId, name: name }), clb, error, complete);
  }

  public addSubGroup(
    eventId: string,
    name: string,
    eventEquipmentGroupId: string,
    index: number,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    this._subscribe(
      this.requestService.post(url.serialize(), {
        event: eventId,
        name: name,
        eventEquipmentGroup: eventEquipmentGroupId,
        index: index.toString()
      }),
      clb,
      error,
      complete
    );
  }

  public addSubGroupMultiple(
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    url.push('createMultiple');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public updateEquipmentSubgroup(
    id: string,
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    url.push(id);
    this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public deleteEquipmentSubgroup(
    id: string,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentSubgroup');
    url.push(id);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public deleteEventEquipment(
    data: { equipmentId: string },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipment');
    url.push(data.equipmentId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public deleteEquipmentGroup(
    data: { equipmentGroupId: string },
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push(data.equipmentGroupId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public updateStaffForEquipmentGroup(
    equipmentGroupId: string,
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventEquipmentGroup');
    url.push(equipmentGroupId);
    url.push('staff');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }
}
