import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../core/services/authentication.service';
import { UserModel } from '../shared/auth/user.model';
import { AppLocaleStorageService } from '../shared/util/local-storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('resetPasswordForm') public resetPasswordForm: NgForm;
  public user: UserModel;
  public language: string;
  public errorMessage: string;
  public complete: boolean = false;
  public loading: boolean = false;
  public passwordInputType: boolean = true;
  constructor(
    public alss: AppLocaleStorageService,
    public translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.user = new UserModel();
    if (this.alss.getItem('language')) {
      this.language = this.alss.getItem('language');
    } else {
      this.language = 'de';
    }
  }

  reset() {
    this.loading = true;
    this.authenticationService.resetPassword(this.resetPasswordForm.value.username).subscribe({
      next: () => {
        this.complete = true;
        this.loading = false;
      },
      error: () => {
        this.resetPasswordForm.form.setErrors({ custom: 'shared.loginPage.validationMessages.noMatch' });
        this.loading = false;
      }
    });
  }

  languageChange(lang: string) {
    this.alss.setItem('language', lang);
    this.translateService.use(lang);
    this.language = lang;
  }
}
