import { UpperCaseFirstPipe, UpperCaseFirstEachPipe } from './ucfirst.pipe';
import { NgModule } from '@angular/core';
import { DateFormatMomentPipe } from './date.format.moment.pipe';
import { AbsoluteValuePipe } from './absolute.value.pipe';
import { CommonModule } from '@angular/common';
import { DateDistanceToNowMomentPipe } from './date.distance.to.now.moment.pipe';
import { PricePipe } from './price.pipe';
import { QuantityPipe } from './quantity.pipe';
import { TimestampFormatMomentPipe } from './timestamp.format.moment.pipe';
import { TimestampDistanceFromNowMomentPipe } from './timestamp.to.now.moment.pipe';
import { DateFormatUtcToLocaleMomentPipe } from './date.format.utcToLocale.moment.pipe';
import { TimestampFormatUtcToLocalMomentPipe } from './timestamp.format.utcToLocale.moment.pipe';
import { TimestampUtcToLocaleDistanceFromNowMomentPipe } from './timestamp.utcToLocale.to.now.moment.pipe';
import { ObjectIterate } from './iterate.object.pipe';
import { SafeStylePipe } from './safe.style.pipe';
import { SafeHtmlPipe } from './safe.html.pipe';
import { SafeUrlPipe } from './safe.url.pipe';
import { ErpTranslatePipe } from './erp-translate.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ErpTranslatePipe,
    UpperCaseFirstPipe,
    UpperCaseFirstEachPipe,
    DateFormatMomentPipe,
    TimestampFormatMomentPipe,
    TimestampDistanceFromNowMomentPipe,
    AbsoluteValuePipe,
    ObjectIterate,
    PricePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SafeStylePipe,
    QuantityPipe,
    DateFormatUtcToLocaleMomentPipe,
    TimestampFormatUtcToLocalMomentPipe,
    DateDistanceToNowMomentPipe,
    UpperCaseFirstPipe,
    TimestampUtcToLocaleDistanceFromNowMomentPipe
  ],
  exports: [
    ErpTranslatePipe,
    UpperCaseFirstPipe,
    UpperCaseFirstEachPipe,
    DateFormatMomentPipe,
    TimestampFormatMomentPipe,
    TimestampDistanceFromNowMomentPipe,
    AbsoluteValuePipe,
    ObjectIterate,
    PricePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SafeStylePipe,
    DateFormatUtcToLocaleMomentPipe,
    TimestampFormatUtcToLocalMomentPipe,
    QuantityPipe,
    DateDistanceToNowMomentPipe,
    UpperCaseFirstPipe,
    TimestampUtcToLocaleDistanceFromNowMomentPipe
  ]
})
export class PipeModule {}
