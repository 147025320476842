<div class="modal-header">
    <span class="modal-caption">{{ 'warning' | translate }} </span>
    <button type="button" class="btn-close close-button" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">
    <div class="modal-message">{{ message | translate }}</div>
</div>
<div style="display: none">{{ developerMessage }}</div>
<div style="display: none" class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ closeBtnName | translate }}</button>
</div>
