/* eslint-disable @typescript-eslint/no-unused-vars */
export class DummyLocaleStorage extends Storage {
  getItem(name: string): string {
    return 'en';
  } // for now we will return en, we should consider multilanguage crawl

  setItem(name: string, value: unknown): void {
    /* do nothing */
  }
}
