import { IEntity } from 'src/app/shared/interface/entity.interface';
import { Injector } from '@angular/core';
import { ErpSelectedStateService } from './state-service/erp.selected.state.service';
import * as moment from 'moment';
import { CallbackFunction } from 'src/app/shared/services/callback.function';
import { Url } from 'src/app/shared/util/url.class';
import { PaginateParameters, SearchParameters } from 'src/app/shared/services/search.service';
import { AbstractService } from 'src/app/shared/util/abstract.service';

export abstract class AbstractEntityService<E extends IEntity> extends AbstractService {
  erpStateService: ErpSelectedStateService;

  constructor(injector: Injector, protected name: string, protected _relations: string[]) {
    super(injector);

    this.erpStateService = injector.get(ErpSelectedStateService);
  }

  public getPreUrl(): Url {
    const url: Url = this.urlService.baseApiUrl;
    url.push(this.erpStateService.getSelectedErpId(), this.name);
    return url;
  }

  public url(): Url {
    const url: Url = this.urlService.baseApiUrl;
    url.push(this.erpStateService.getSelectedErpId());
    return url;
  }

  public getBaseUrl(): Url {
    return this.urlService.baseUrl;
  }

  get baseUrl(): Url {
    return this.urlService.baseUrl;
  }

  // *******************************************************************************
  // *-----------------------------------------------------------------------------*
  // *--------------------------------REST-FUL-------------------------------------*
  // *-----------------------------------------------------------------------------*
  // *******************************************************************************

  public index<T>(clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url = this.getPreUrl();
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public show<T>(id: string | number, clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url: Url = this.getPreUrl().push(id);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public edit<T>(id: string | number, clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url = this.getPreUrl().push(id, 'edit');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public create<T>(clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url = this.getPreUrl().push('create');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public store<T>(data: any, clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url = this.getPreUrl();
    return this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public update<T>(
    id: number,
    data: any,
    clb: CallbackFunction<T>,
    error?: CallbackFunction<any>,
    complete?: () => void
  ) {
    const url = this.getPreUrl().push(id);
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public delete<T>(id: number, clb: CallbackFunction<T>, error?: CallbackFunction<any>, complete?: () => void) {
    const url = this.getPreUrl();
    url.push(id);
    return this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public deleteProduct<T>(
    groupId: number,
    id: number,
    clb: CallbackFunction<T>,
    error?: CallbackFunction<any>,
    complete?: () => void
  ) {
    const url = this.url();
    url.push('product-group');
    url.push(groupId);
    url.push('removeProduct');
    url.push(id);
    return this._subscribe(this.requestService.put(url.serialize(), ''), clb, error, complete);
  }

  // //-----------------------------------------------------------------------------------------

  public search<T>(searchParameters: SearchParameters<T>) {
    const url: Url = this.getPreUrl();
    url.path.push('search');
    url.put('page_size', searchParameters.limit);
    url.put('page', searchParameters.page);
    url.put('search', searchParameters.search);
    if (searchParameters.searchView !== undefined) {
      url.put('searchView', searchParameters.searchView);
    }

    if (searchParameters.filters) {
      url.putArray('filters', searchParameters.filters);
    }
    return this._subscribe(
      this.requestService.get(url.serialize()),
      searchParameters.clb,
      searchParameters.error,
      searchParameters.complete
    );
  }

  public paginate<T>(paginateParameters: PaginateParameters<T>) {
    const url: Url = this.getPreUrl();
    url.push('paginate');
    url.put('page_size', paginateParameters.limit);
    url.put('page', paginateParameters.page);
    if (paginateParameters.orderBy) {
      url.put('order_by', paginateParameters.orderBy);
    }
    if (paginateParameters.orderDirection) {
      url.put('order_direction', paginateParameters.orderDirection);
    }
    if (paginateParameters.timespan) {
      url.put('from', Date.parse(moment.utc(paginateParameters.timespan.from).toDate().toUTCString()) / 1000);
      url.put('to', Date.parse(moment.utc(paginateParameters.timespan.to).toDate().toUTCString()) / 1000);
    }
    return this._subscribe(
      this.requestService.get(url.serialize()),
      paginateParameters.clb,
      paginateParameters.error,
      paginateParameters.complete
    );
  }
}
