import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './guard/redirect.guard';
import { RoutekeeperGuard } from './guard/routekeeper.guard';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForbiddenComponent } from './shared/forbidden/forbidden.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectGuard],
    component: NotFoundComponent,
    pathMatch: 'full'
  },
  {
    canActivate: [RoutekeeperGuard],
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then((m) => m.PanelModule)
  },
  {
    path: 'signin',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    canActivate: [RoutekeeperGuard],
    path: 'login',
    component: LoginComponent
  },
  {
    canActivate: [RoutekeeperGuard],
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'dashboard',
    redirectTo: 'panel/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'catering',
    redirectTo: 'panel/catering',
    pathMatch: 'full'
  },
  {
    path: 'analytics',
    redirectTo: 'panel/analytics',
    pathMatch: 'full'
  },
  {
    path: 'menucreator',
    redirectTo: 'panel/menucreator',
    pathMatch: 'full'
  },
  {
    path: 'erp-client',
    redirectTo: 'panel/erp',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    redirectTo: 'panel/settings',
    pathMatch: 'full'
  },
  {
    path: 'shop',
    redirectTo: 'panel/shop',
    pathMatch: 'full'
  },
  {
    canActivate: [RoutekeeperGuard],
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then((m) => m.SupplierModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
