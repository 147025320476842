import { Injectable, Injector } from '@angular/core';
import { IErp } from '../../entity/erp';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { ErpSelectedStateService } from './erp.selected.state.service';

@Injectable()
export class ErpAvailableStateService {
  public availableErps: IErp[];
  public availableErpsSubject: BehaviorSubject<IErp[]> = new BehaviorSubject([]);
  public availableErpsObservable: Observable<IErp[]>;

  erpStateService: ErpSelectedStateService;
  sub: Subscription;

  constructor(injector: Injector) {
    this.availableErpsObservable = this.availableErpsSubject.asObservable();
  }

  getAvailables(): IErp[] {
    return Object.assign([], this.availableErps);
  }

  setAvailables(erps: IErp[]) {
    this.availableErps = Object.assign([], erps);
    this.availableErpsSubject.next(erps);
  }

  observeAvailables(): Observable<IErp[]> {
    return this.availableErpsObservable;
  }
}
