import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/* This is a component which we pass in modal */

@Component({
  selector: 'app-boson-modal-content',
  templateUrl: './notify-modal.component.html'
})
export class NotifyModalComponent {
  caption: string;

  captionDesc?: string;

  closeBtnName: string;

  developerMessage?: string;

  message = '';

  constructor(public bsModalRef: BsModalRef) {}
}
