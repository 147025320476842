import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from 'src/app/core/services/request.service';
import { UrlService } from 'src/app/core/services/url.service';
import { AbstractService } from '../util/abstract.service';
import { Url } from '../util/url.class';
import { NavBarItem } from './navbar-item.class';

@Injectable({
  providedIn: 'root'
})
export class NavbarService extends AbstractService {
  public admin?: boolean;
  private _menu: NavBarItem[];
  //This is for the admin panel eka commented
  public isErp = true;
  public hide = false;

  urlService: UrlService;
  requestService: RequestService;

  get menu(): NavBarItem[] {
    return this._menu;
  }
  set menu(value: NavBarItem[]) {
    this._menu = value;
  }

  constructor(injector: Injector) {
    super(injector);
    this.urlService = injector.get(UrlService);
    this.requestService = injector.get(RequestService);
  }
  public loadNavbar() {
    const url: Url = this.urlService.baseAppUrl;
    url.path.push('client-navbar-items');

    this.requestService
      .get(url.serialize())
      .pipe(
        map((data: any) => {
          this.menu = data.payload;
        })
      )
      .subscribe();
  }

  updateDesignModeForUser(designMode: any, clb: (e: any) => any, error?: (e: any) => any, complete?: () => any) {
    const url: Url = this.urlService.baseAppUrl;
    url.push('switch-design-mode');
    url.put('designMode', designMode);
    this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }
}
