import { Injectable, Injector } from '@angular/core';
import { EventCatering } from '../interfaces/event-catering.interface';
import { IResponse } from '../interfaces/response.interface';
import { Subject } from 'rxjs';
import { Weather } from '../interfaces/weather.interface';
import { map } from 'rxjs/operators';
import { Allergen } from '../interfaces/allergen.interface';
import { Additive } from '../interfaces/additive.interface';
import { Team } from '../interfaces/team.interface';
import { Additional } from '../interfaces/additional.interface';
import { EventUser } from '../interfaces/user-logged.interface';
import { EventCateringCommunicationService } from './event-catering-communication.service';
import { AbstractService } from 'src/app/shared/util/abstract.service';
import { CallbackFunction } from 'src/app/shared/interface/callback-function.interface';
import { IErrorServerResponse } from 'src/app/shared/interface/error.server.response.interface';
import { Url } from 'src/app/shared/util/url.class';
import { IPayload } from 'src/app/shared/interface/payload.interface';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class EventCateringService extends AbstractService {
  public eventsChanged: Subject<boolean> = new Subject();
  public allergens: Allergen[] = [];
  public additives: Additive[] = [];
  public additionals: Additional[] = [];
  public events: EventCatering[] = [];
  public eventWeather: any;
  public showHeaderMenu = new Subject<boolean>();

  constructor(injector: Injector, public eventCateringCommunicationService: EventCateringCommunicationService) {
    super(injector);
  }

  public getAllEvents(
    costCenterId: string,
    data: { pageSize?: number; orderDirection?: string; currentPage?: number },
    clb: CallbackFunction<IPayload<EventCatering[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');

    data.pageSize ? url.put('pageSize', data.pageSize) : url.put('pageSize', 20);
    url.put('orderBy', 'timeBegin');
    data.orderDirection ? url.put('orderDirection', data.orderDirection) : url.put('orderDirection', 'DESC');
    data.currentPage ? url.put('page', data.currentPage) : url.put('page', 1);

    return this._subscribe(
      this.requestService.get<IResponse<EventCatering[]>>(url.serialize()).pipe(
        map((data) => {
          // convert the dates from string to Date objects
          (data.payload as IPayload<EventCatering[]>).data.forEach((event) => {
            event.timeBegin = new Date(event.timeBegin);
            if (event.timeEnd !== null) {
              event.timeEnd = new Date(event.timeEnd);
            }
          });
          return data;
        })
      ),
      clb,
      error,
      complete
    );
  }

  public getEventsByFilter(
    data: any,
    clb: CallbackFunction<IResponse<EventCatering[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('filters');
    url.put('operator', 'AND');
    url.put('pageSize', '20');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public getEventsByFilterDateSearch(
    data: any,
    page: number,
    clb: CallbackFunction<IPayload<EventCatering[]>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('searchWithFilters');
    url.put('operator', 'AND');
    url.put('pageSize', '20');
    url.put('page', page);
    url.put('orderBy', 'timeBegin');
    data.orderDirection ? url.put('orderDirection', data.orderDirection) : url.put('orderDirection', 'DESC');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public exportFilteredEvents(
    data: any,
    page: number,
    clb: CallbackFunction<IPayload<EventCatering[]>>,
    error?: (error: HttpErrorResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('generateReport');
    url.put('operator', 'AND');
    url.put('pageSize', '20');
    url.put('page', page);
    url.put('orderBy', 'timeBegin');
    data.orderDirection ? url.put('orderDirection', data.orderDirection) : url.put('orderDirection', 'DESC');
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public getEventsByDateRange(
    data: any,
    clb: CallbackFunction<IResponse<EventCatering[]>>,
    error?: (error: HttpErrorResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('searchRange');
    data.pageSize ? url.put('pageSize', data.pageSize) : url.put('pageSize', 20);
    url.put('orderBy', 'timeBegin');
    data.orderDirection ? url.put('orderDirection', data.orderDirection) : url.put('orderDirection', 'DESC');
    data.currentPage ? url.put('page', data.currentPage) : url.put('page', 1);
    this._subscribe(this.requestService.post(url.serialize(), data), clb, error, complete);
  }

  public getEvent(
    eventId: string,
    clb: CallbackFunction<EventCatering>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getEventWeather(
    eventId: string,
    clb: CallbackFunction<IResponse<Weather>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    url.push('weather');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getEventAllergens(
    eventId: string,
    clb: CallbackFunction<Allergen[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    url.push('allergens');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getEventAdditives(
    eventId: string,
    clb: CallbackFunction<Additive[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    url.push('additives');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getEventAdditionals(
    eventId: string,
    clb: CallbackFunction<Additional[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    url.push('additionals');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getEventStaff(
    eventId: string,
    clb: CallbackFunction<IResponse<Team>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventStaff');
    url.push('event');
    url.push(eventId);
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public deleteEventStaff(
    eventStaffId: string,
    eventId: string,
    clb: CallbackFunction<IResponse<Team>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventStaff');
    url.push(eventStaffId);
    url.push('event');
    url.push(eventId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public createNewEvent(
    event: any,
    clb: CallbackFunction<EventCatering>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    this._subscribe(this.requestService.post(url.serialize(), event), clb, error, complete);
  }

  public editEvent(
    eventId: string,
    event: any,
    clb: CallbackFunction<EventCatering>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    this._subscribe(this.requestService.put(url.serialize(), event), clb, error, complete);
  }

  public deleteEvent(
    eventId: string,
    clb: CallbackFunction<EventCatering>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    this._subscribe(this.requestService.delete(url.serialize()), clb, error, complete);
  }

  public getLoggedUser(
    clb: CallbackFunction<EventUser>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventStaff');
    url.push('currentUser');
    this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public getCurrentEventUser(
    eventId: string,
    clb: CallbackFunction<EventUser>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventStaff');
    url.push('currentEventUser');
    url.push(eventId);
    this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public getCostCenters(
    clb: CallbackFunction<any[]>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('dashboard');
    url.push('costCenters');
    return this._subscribe(this.requestService.get(url.serialize()), clb, error, complete);
  }

  public setDefaultCostCenterForCurrentUser(
    costCenterId: number,
    clb: CallbackFunction<IResponse<EventCatering>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('setUserCostCenter');
    url.push(costCenterId);
    return this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public setCurrentEventForEventStaff(
    data: any,
    clb: CallbackFunction<IResponse<any>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('eventStaff');
    url.push('setCurrentEvent');
    return this._subscribe(this.requestService.put(url.serialize(), data), clb, error, complete);
  }

  public cloneEvent(
    eventId: string,
    clb: CallbackFunction<IResponse<EventCatering>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push(eventId);
    url.push('clone');
    return this._subscribe(this.requestService.post(url.serialize(), {}), clb, error, complete);
  }

  public search(
    body: any,
    data: { pageSize?: number },
    clb: CallbackFunction<IResponse<EventCatering>>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('eventCatering');
    url.push('event');
    url.push('search');
    data.pageSize ? url.put('pageSize', data.pageSize) : url.put('pageSize', 20);
    this._subscribe(this.requestService.post(url.serialize(), body), clb, error, complete);
  }

  /** add events to production plan */
  public addEventsToPP(
    body: any,
    clb: CallbackFunction<any>,
    error?: (error: IErrorServerResponse) => void,
    complete?: () => void
  ) {
    const url: Url = this.urlService.baseApiUrl;
    url.push('productionPlan');
    url.push('plan');
    url.push('createFromEvents');
    this._subscribe(this.requestService.post(url.serialize(), body), clb, error, complete);
  }

  public getLocationImageUrl(thumbnailFileName: string) {
    const url: Url = new Url();
    url.push('eventCatering');
    url.push('images');
    url.push('EventLocation');
    url.push(thumbnailFileName);

    return url.serialize();
  }
}
